<template>
  <div>
      <GlobalInfoBar title="门店列表"/>
      <GlobalChunk
          :padding="[20,20,20,20]">
        <GlobalForm
            :form-item-list="formItemList"
            :inline="true"
            round
            @handleConfirm="query"
            confirmBtnName="查询"
        >
          <div slot="AddSlot">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="addMerchant">新增门店</el-button>
          </div>
        </GlobalForm>
      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="注册店员" slot="num" align="center">
          <template slot-scope="{ row }">
            <el-popover
                placement="right"
                width="700"
                @show="staffPopoverShow(row)"
                trigger="click">
            <GlobalForm
                :form-item-list="staffPopover.formItemList"
                :inline="true"
                round
                @handleConfirm="staffQuery"
                confirmBtnName="查询"
            ></GlobalForm>
            <span class="popover-sore-num">店员数量:<span>{{ row.num }}</span></span>
            <GlobalTable
                :columns="staffPopover.tableColumns"
                :data="staffPopover.tableData"
                :currentPage="staffPopover.curPage"
                :total="staffPopover.totalElements"
                :page-size="5"
                @handleCurrentChange="staffCurrentChange">
              <el-table-column label="头像" slot="headimg" align="center">
                <template slot-scope="{ row }">
                  <el-image
                      style="width:  50px; height:  50px"
                      :src="row.headimg"
                      :preview-src-list="[row.headimg]"
                      @click.stop="closePreview">
                  </el-image>
                </template>
              </el-table-column>
            </GlobalTable>
            <a href="javascript:" slot="reference" :class="{'no-count':!row.num}">{{ row.num }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="Apr门店" slot="is_open" align="center">
          <template slot-scope="{ row }">
            <el-switch :value="row.is_apr" :active-value="1" :inactive-value="0" @change="(e) => changeOpen(row, e)"/>
          </template>
        </el-table-column>
        <el-table-column label="最后更新时间" slot="add_time" align="center">
          <template slot-scope="{ row }">
            {{row.add_time||'--'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editInfo(row)">修改</el-button>
            <el-popover
                placement="left"
                width="300"
                v-model="row.isShowPopover">
              <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
              <div class="btns">
                <el-button size="small" plain @click="row.isShowPopover = false">取消</el-button>
                <el-button type="primary" size="small" @click="row.isShowPopover = false;deleteRow(row)">确定</el-button>
              </div>
              <el-button type="text" class="delete-btn" slot="reference" >删除</el-button>
            </el-popover>

          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
    <el-dialog :title="dialog.type+'门店'" :visible.sync="dialog.isShow" width="500px">
      <div class="dialog-notice">说明:门店名称不能重复添加，名称长度限制25字内</div>
      <el-form ref="dialogForm" :model="dialog.form" label-width="80px" size="small" class="dialog-form"
               :rules="dialog.rules">
        <el-form-item prop="shop_name" label="门店名称"  label-width="100px">
          <el-input v-model="dialog.form.shop_name" placeholder="请输入门店名称 " maxlength="25"></el-input>
        </el-form-item>

        <el-form-item prop="group_id" label="所属商家"  label-width="100px">
          <el-select v-model="dialog.form.group_id" placeholder="请选择所属商家" filterable @change="groupChange">
            <el-option
                v-for="item in dialog.merchants"
                :key="item.group_id"
                :label="item.group_name"
                :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="group_id" label="所属分销商" label-width="100px">
          <el-select v-model="dialog.form.fxs_id" placeholder="请选择所属分销商" filterable>
            <el-option
                v-for="item in dialog.distributors"
                :key="item.fxs_id"
                :label="item.name"
                :value="item.fxs_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="address" label="所属区域" v-if="dialog.isShow"  label-width="100px">
          <el-cascader v-model="dialog.form.address" :props="{lazy:true,lazyLoad:loadArea,label:'name',value:'id'}"></el-cascader>
        </el-form-item>

        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow=false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  groupFxsList,
  groupList,
  groupShopList,
  addGroupShop,
  groupShopOpen,
  selectGroupList,
  delGroup, groupShopMemberList, delGroupShop
} from "@/utils/request/partnerManage";
import api from "@/utils/request/index";
import {selectGroupFxs} from "../../utils/request/partnerManage";

export default {
  name: "storeList",
  data() {
    return {
      formItemList:[ {
        key: 'storeName',
        type: 'input',
        labelName: '门店名称:',
        placeholder: '请输入门店名称'
      }, {
        key: 'merchant',
        idKey: 'group_id',
        labelKey: 'group_name',
        type: 'selectFilterable',
        labelName: '所属商家:',
        option: [],
        placeholder: '请选择所属商家'
      }, {
        key: 'distributor',
        idKey: 'fxs_id',
        labelKey: 'name',
        type: 'selectFilterable',
        labelName: '所属分销商:',
        option: [],
        placeholder: '请选择所属分销商'
      }],
      storeName: '',
      merchant: '',
      distributor: '',
      tableColumns: [
        {label: "门店名称", prop: "shop_name"},
        {label: "所属商家", prop: "group_name"},
        {label: "所属分销商", prop: "fxs_name"},
        {label: "省份", prop: "province_name"},
        {label: "城市", prop: "city_name"},
        {label: "区县", prop: "district_name"},
        {slotName: "num"},
        {slotName:"is_open"},
        {slotName: "add_time"},
        {slotName: "operation"},
      ],
      tableData:[],
      curPage: 1,
      totalElements:0,
      dialog: {
        isShow: false,
        type: '新增',
        form: {
          group_shop_id: '',
          shop_name:'',
          group_id:'',
          fxs_id: '',
          address: [],
          province:'',
          city:'',
          district:''
        },
        rules: {
          shop_name:[{required:true,message:'请填写门店名称',trigger:'blur'}],
          group_id:[{required:true,message:'请选择所属商家',trigger:'change'}],
          address:[{required:true,message:'请选择所在区域',trigger:'change'}],
        },
        merchants:[],
        distributors:[]
      },
      staffPopover: {
        tableColumns: [
          {slotName: "headimg"},
          {label: "店员姓名", prop: "name"},
          {label: "手机号码", prop: "phone"},
          {label: "注册时间", prop: "add_time"},
        ],
        phone: '',
        group_id: '',
        group_shop_id: '',
        tableData: [],
        curPage: 1,
        totalElements: 0,
        formItemList: [{
          key: 'phone',
          type: 'input',
          labelName: '注册手机:',
          placeholder: '请输入注册手机号查询'
        }]
      }
    }
  },
  mounted() {
    this.getStores()
    this.getMerchants()
    this.getDistributors()
  },
  methods:{
    changeOpen(row, value) {
      groupShopOpen({
        group_shop_id: row.group_shop_id,
        is_apr: value
      }).then(() => {
        this.$message.success('操作成功！')
        this.getStores()
      })
    },
    getStores() {
      groupShopList({
        page:this.curPage,
        limit:10,
        shop_name:this.storeName,
        group_id:this.merchant,
        fxs_id:this.distributor,
      }).then(res=>{
        this.tableData=res.data||[]
        this.totalElements=res.count||0
      })
    },
    getMerchants() {
      selectGroupList({page:1,limit:1000000}).then(res=>{
        this.formItemList[1].option=res.data||[]
        this.dialog.merchants=res.data||[]

      })
    },
    getDistributors() {
      groupFxsList().then(res=>{
        // console.log(res,'33333333333');
        this.formItemList[2].option=res.data||[]
      })
    },
    groupChange() {
      this.dialog.distributors=[]
      this.getDistributors2()
    },
    getDistributors2() {
      selectGroupFxs({group_id:this.dialog.form.group_id}).then(res=>{
        this.dialog.distributors=res.data||[]
      })
    },
    addMerchant() {
      this.dialog.isShow = true
      this.dialog.type = '新增'
      this.dialog.form = {
        group_shop_id: '',
        shop_name:'',
        group_id:'',
        province:'',
        city:'',
        district:'',
        address: []
      }
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
      })
    },
    editInfo(row) {
      this.dialog.isShow = true
      this.dialog.type = '修改'
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
        let form = {
          group_shop_id: row.group_shop_id,
          shop_name:row.shop_name,
          group_id:row.group_id,
          fxs_id:row.fxs_id,
          province:'',
          city:'',
          district:'',
          address: (row.province&&row.city&&row.district)?([row.province,row.city,row.district]):[]
        }
        this.$set(this.dialog, 'form', form)
        this.getDistributors2()
      })
    },
    deleteRow(row) {
      delGroupShop({group_shop_id: row.group_shop_id}).then(res => {
        this.$message.success(res.msg || '删除成功')
        this.getStores()
      }).catch(err => {
      })
    },
    currentChange(page) {
      this.curPage = page
      this.getStores()
    },
    query(data) {
      this.storeName=data.storeName
      this.merchant=data.merchant
      this.distributor=data.distributor
      this.curPage=1
      this.getStores()
    },
    loadArea(node, resolve) {
      const { level } = node;
      api.getRegion({pid:node.data&&node.data.id||0}).then(res=>{
        let list = res.data || [];
        if(node.level===2){
          resolve(list.map(item=>{
            item.leaf=true
            return item
          }))
        }else {
          resolve(list)
        }

      });
    },
    staffPopoverShow(row) {
      this.staffPopover.totalElements = 0
      this.staffPopover.phone = ''
      this.staffPopover.group_id = row.group_id
      this.staffPopover.group_shop_id = row.group_shop_id
      this.staffPopover.curPage = 1
      this.groupShopMemberList()
    },
    groupShopMemberList() {
      groupShopMemberList({
        page:this.staffPopover.curPage,
        limit:5,
        phone:this.staffPopover.phone,
        name: '',
        group_shop_id:this.staffPopover.group_shop_id,
        // group_shop_id:this.staffPopover.group_shop_id
      }).then(res=>{
        this.staffPopover.tableData=res.data
        this.staffPopover.totalElements=res.count
      })
    },
    staffQuery(data) {
      this.staffPopover.curPage = 1
      this.staffPopover.phone = data.phone
      this.groupShopMemberList()
    },
    staffCurrentChange(page) {
      this.staffPopover.curPage = page
      this.groupShopMemberList()
    },
    confirm() {
      this.$refs.dialogForm.validate(valid=>{
        if (valid) {
          let {form}=this.dialog
          addGroupShop({
            group_shop_id:form.group_shop_id,
            shop_name:form.shop_name,
            group_id:form.group_id,
            fxs_id:form.fxs_id,
            province:form.address[0],
            city:form.address[1],
            district:form.address[2]
          }).then(res => {
            this.$message.success(res.msg || this.dialog.type + '门店成功')
            this.dialog.isShow = false

            this.curPage = 1
            this.getStores()
          }).catch(err => {
          })
        }
      })
    },
    closePreview() {
      // 获取遮罩层dom
      setTimeout(function(){
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if(e.target.parentNode.className === 'el-image-viewer__actions__inner') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      },300)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
.el-input, .el-select,.el-cascader {
  width: 300px;
}
}

.dialog-btns {
  margin: 30px 0 30px 60px;

.el-button {
  margin-left: 20px;
}
}

.el-popover {
.el-icon-warning {
  color: #FAAD14;
  margin-right: 5px;
  font-size: 16px;
}

.btns {
  text-align: right;
  margin-top: 20px;
}
}
.delete-btn{
  color:red;
  margin-left: 10px;
}
.el-popover{
  position: relative;
}
.popover-sore-num {
  position: absolute;
  right: 20px;
  top: 20px;
span{
  color: red;
}
}
.staff-avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.no-count{
  color: #000;
  pointer-events: none;
}
</style>
